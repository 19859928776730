$ball-scale-ripple-multiple-duration: 1.25s;
$ball-scale-ripple-multiple-delay: 0s;
$ball-scale-ripple-multiple-easing: cubic-bezier(0.21, 0.53, 0.56, 0.8);
$ball-scale-ripple-multiple-border-color: #4247C3;

.loader {
  height: 100%;
  width: 100%;
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    scale: 0.7;
  }

  .ball-scale-ripple-multiple {
    position: relative;
    transform: translateX(33px);

    > div:nth-child(0) {
      animation-delay: -0.8s;
    }

    > div:nth-child(1) {
      animation-delay: -0.6s;
    }

    > div:nth-child(2) {
      animation-delay: -0.4s;
    }

    > div:nth-child(3) {
      animation-delay: -0.2s;
    }

    > div {
      animation-fill-mode: both;
      position: absolute;
      top: -2px;
      left: -26px;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 2px solid $ball-scale-ripple-multiple-border-color;
      animation: ball-scale-ripple-multiple $ball-scale-ripple-multiple-duration
        $ball-scale-ripple-multiple-delay infinite
        $ball-scale-ripple-multiple-easing;
    }
  }
}

@keyframes ball-scale-ripple-multiple {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

   
